import React from "react"
import { QueryClient, QueryClientProvider } from "react-query";
import { ApolloProvider } from "@apollo/react-hooks"
import * as Sentry from "@sentry/react";
import { client } from "./client"

const queryClient = new QueryClient();


Sentry.init({
    dsn: "https://64dd78c306b0004b81cf678c6dff76aa@o424937.ingest.us.sentry.io/4506956092735488",
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            // useLocation,
            // useNavigationType,
            // createRoutesFromChildren,
            // matchRoutes
        }),
        Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
export const wrapRootElement = ({ element }) => {
  return <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </QueryClientProvider>
}
