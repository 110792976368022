import { isBrowser } from "../utilities/helpers"

export default {
  Mutation: {},
}

const defaults = isBrowser()
  ? {
      localAuthToken: localStorage.getItem("auth_token"),
      sessionAuthToken: sessionStorage.getItem("auth_token"),
      changePassword: localStorage.getItem("change_password"),
    }
  : {
    localAuthToken: null,
    sessionAuthToken: null,
    changePassword: null,
  }

const resolvers = {}

export { defaults, resolvers }
