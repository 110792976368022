const APP_ROUTES = {
  login: "/login",
  signUp: "/signup",
  home: "/home",
  intro: "/intro",
  journeys: "/challenges",
  leaderBoard: "/scoreboard",
  feed: "/feed",
  team: "/team",
  progress: "/progress",
}

const AUTH_ROUTES = [APP_ROUTES.signUp, APP_ROUTES.login]

module.exports = { APP_ROUTES, AUTH_ROUTES }
