const axios = require("axios")
// const { API_BASE_URL } = require("./config");
const { API_BASE_URL } = require("../constants")

const {
  objectToCamelCase,
  rasterizeErrors,
  payloadToSnakeCase,
} = require("./response.utils")

const optionsEnum = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
}

class API {
  constructor() {
    this.optionsObject = {
      headers: {},
      transformCamelCase: false,
    }
    this.client = axios.create({
      baseURL: API_BASE_URL,
      timeout: 30000,
    })
    this.service = null
  }

  setMethod() {
    const assign = method => {
      this.optionsObject.method = method
      return this
    }
    return {
      get: () => assign(optionsEnum.GET),
      post: () => assign(optionsEnum.POST),
      delete: () => assign(optionsEnum.DELETE),
      put: () => assign(optionsEnum.PUT),
      patch: () => assign(optionsEnum.PATCH),
    }
  }

  setBody(body) {
    this.optionsObject.body = JSON.stringify(body)
    return this
  }

  setCamelCaseTransformer(bool) {
    this.optionsObject.transformCamelCase = bool
    return this
  }

  addHeader(key, value) {
    this.optionsObject.headers[`${key}`] = value
    return this
  }

  setAuthorizationToken(token) {
    this.addHeader("Authorization", `Bearer ${token}`)
    return this
  }

  setAuthorizationXToken(token) {
    this.addHeader("X-Token", token)
    return this
  }

  setAPIKey(key) {
    if (this.optionsObject.headers.Authorization) {
      throw new Error("Authorisation token is already set")
    }
    this.addHeader("x-api-key", key)
    return this
  }

  setService(name) {
    this.service = name
    return this
  }

  // eslint-disable-next-line no-underscore-dangle,class-methods-use-this
  _formatResponse(data) {
    if (Array.isArray(data)) {
      return data.map(objectToCamelCase)
    }
    if (typeof data === "object") {
      return objectToCamelCase(data)
    }

    return data
  }

  async process(endpoint, params = {}) {
    if (!this.optionsObject.headers["Content-Type"]) {
      this.optionsObject.headers["Content-Type"] = "application/json"
    }

    const url = `${this.service ? "/" : ""}${this.service || ""}${endpoint}`
    const options = {}

    if (this.optionsObject.body) {
      const data = this.optionsObject.transformCamelCase
        ? payloadToSnakeCase(this.optionsObject.body)
        : this.optionsObject.body
      options.data = JSON.parse(JSON.stringify(data))
    }

    if (params) {
      const data = this.optionsObject.transformCamelCase
        ? payloadToSnakeCase(params)
        : params
      options.params = JSON.parse(JSON.stringify(data))
    }

    try {
      const request = await this.client.request({
        method: this.optionsObject.method,
        url,
        headers: this.optionsObject.headers,
        ...options,
      })
      request.data = this.optionsObject.transformCamelCase
        ? // eslint-disable-next-line no-underscore-dangle
          this._formatResponse(request.data)
        : request.data

      return request
    } catch (e) {
      const data = (e.response && e.response.data) || {}
      const error = {
        ...data,
        code: e.response ? e.response.status : e.status,
        message: e.response
          ? data.message || e.response.statusText || e.message
          : e.message,
      }
      throw rasterizeErrors(error)
    }
  }
}

export default API
