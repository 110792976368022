// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-journey-template-js": () => import("/home/travis/build/Ciarus/1Change-App/src/templates/journey-template.js" /* webpackChunkName: "component---src-templates-journey-template-js" */),
  "component---src-templates-activity-template-js": () => import("/home/travis/build/Ciarus/1Change-App/src/templates/activity-template.js" /* webpackChunkName: "component---src-templates-activity-template-js" */),
  "component---src-templates-inspiration-template-js": () => import("/home/travis/build/Ciarus/1Change-App/src/templates/inspiration-template.js" /* webpackChunkName: "component---src-templates-inspiration-template-js" */),
  "component---src-pages-404-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenges-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/challenges.js" /* webpackChunkName: "component---src-pages-challenges-js" */),
  "component---src-pages-choose-password-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/choose-password.js" /* webpackChunkName: "component---src-pages-choose-password-js" */),
  "component---src-pages-feed-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/feed.js" /* webpackChunkName: "component---src-pages-feed-js" */),
  "component---src-pages-home-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-login-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-progress-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/progress.js" /* webpackChunkName: "component---src-pages-progress-js" */),
  "component---src-pages-resilience-intro-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/resilience-intro.js" /* webpackChunkName: "component---src-pages-resilience-intro-js" */),
  "component---src-pages-resilience-result-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/resilience-result.js" /* webpackChunkName: "component---src-pages-resilience-result-js" */),
  "component---src-pages-resilience-test-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/resilience-test.js" /* webpackChunkName: "component---src-pages-resilience-test-js" */),
  "component---src-pages-scoreboard-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/scoreboard.js" /* webpackChunkName: "component---src-pages-scoreboard-js" */),
  "component---src-pages-signup-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-team-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-welcome-js": () => import("/home/travis/build/Ciarus/1Change-App/src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

