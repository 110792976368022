import * as loadImage from "blueimp-load-image"
import { AUTH_ROUTES, slugOrder } from "../constants"
import countries from "../assets/countries.json"

export const isBrowser = () => typeof window !== "undefined"

export const ImageCompressor = (
  file,
  asFile,
  options = {
    maxWidth: 500,
    maxHeight: 500,
  }
) => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader()
    reader.onload = upload => {
      // if (file.size / 1024 > 5000) {
      // 	reject("Image cannot be larger than 5 Mb", null);
      // 	return;
      // }
      const loadingImage = loadImage(
        file,
        async img => {
          if (img.type === "error") {
            reject("Something went wrong")
          } else {
            const imageData = img.toDataURL()
            if (asFile) {
              const res = await fetch(imageData)
              const blob = await res.blob()
              const file = new File([blob], "picture.jpg", {
                type: "image/jpeg",
              })
              resolve({ file, imageData })
            } else {
              resolve({ imageData })
            }
          }
        },
        {
          maxWidth: options.maxWidth,
          maxHeight: options.maxHeight,
          cover: true,
          orientation: true,
        }
      )
      loadingImage.onerror = () => {
        reject("Something went wrong")
      }
    }
    try {
      reader.readAsDataURL(file)
    } catch (error) {}
  })
}

const getJourneyPoints = (journeyTitle, questions) => {
  let journeyScore = 0
  const journeyQuestions = questions.filter(
    question => question.journey.title === journeyTitle
  )
  for (let index = 0; index < journeyQuestions.length; index++) {
    journeyScore += journeyQuestions[index].answer
  }
  return journeyScore
}

export const sortJourneysByScore = (resilienceTest, allJourneys) => {
  const { questions } = resilienceTest
  const journeys = []

  for (let index = 0; index < allJourneys.length; index++) {
    const journey = allJourneys[index]
    if (journey.companySpecific) continue
    const journeyScore = getJourneyPoints(journey.title, questions)
    journeys.push({
      id: journey.contentful_id,
      title: journey.title,
      score: journeyScore,
    })
  }
  return journeys.sort((a, b) => a.score - b.score)
}

export const getJourneyCardDetails = (journeys, allJourneys) => {
  return journeys
    .map(journey => {
      return allJourneys.find(
        allJourney => allJourney.contentful_id === journey.contentfulId
      )
    })
    .filter(detail => detail !== undefined)
}

export const formatServerError = errorMsg => {
  return errorMsg.replace("GraphQL error:", " ").trim()
}

const isValidURL = str => {
  var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
  return urlRegex.test(str)
}

export const isAuthRoute = path => {
  if (isValidURL(path)) {
    const url = new URL(path)
    for (const eachAuthRoute of AUTH_ROUTES) {
      if (url.pathname && url.pathname.includes(eachAuthRoute)) {
        return true
      }
    }
  }
  return false
}

export const isValidEmail = email => {
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const getCountryNameByCode = countryCode => {
  const country = countries.find(c => c.code === countryCode)
  return country ? country.name : ""
}

// Helper function to sort data by predefined slug order
const sortDataBySlugOrder = data => {
  // Create a Map for the order for fast lookup
  const orderMap = new Map(slugOrder.map((slug, index) => [slug, index]))

  // Sort the data according to the order Map
  return data.sort((a, b) => {
    // Get the order index, if the item doesn't exist in the order, place it at the end
    const orderA = orderMap.has(a.slug)
      ? orderMap.get(a.slug)
      : slugOrder.length
    const orderB = orderMap.has(b.slug)
      ? orderMap.get(b.slug)
      : slugOrder.length

    return orderA - orderB
  })
}

export const getDataInDifferentSets = mergedJourneysSet => {
  // Object to hold the categorized data
  const categories = {}

  // Iterate through each object in the input array
  mergedJourneysSet.forEach(item => {
    const subtitle = item.subtitle.subtitle // Extract the subtitle

    // If the category does not exist, initialize it
    if (!categories[subtitle]) {
      categories[subtitle] = []
    }

    // Add the current item to its corresponding category
    categories[subtitle].push(item)
  })

  // Convert the categories object into an array, excluding "Before kick-off" for now
  let result = Object.keys(categories)
    .filter(subtitle => subtitle !== "Before kick-off")
    .map(subtitle => {
      // Extract week number if it's a week, otherwise set to null
      const weekNumber = subtitle.startsWith("Week ")
        ? parseInt(subtitle.replace("Week ", ""), 10)
        : null
      return {
        name: subtitle,
        weekNumber: weekNumber,
        data: sortDataBySlugOrder(categories[subtitle]),
      }
    })

  // Sort the result array by week number in descending order, maintaining the order within the same week
  result.sort((a, b) => {
    // In case there is no week number, treat it as zero to place it at the end after sorting
    const weekA = a.weekNumber || 0
    const weekB = b.weekNumber || 0
    return weekB - weekA
  })

  // If "Before kick-off" exists, add it as the last element with a week number of 0
  if (categories["Before kick-off"]) {
    result.push({
      name: "Before kick-off",
      weekNumber: 0,
      data: categories["Before kick-off"],
    })
  }

  return result
}

export const getAllJourneysDataSorted = mergedJourneysSet => {
  // Object to hold the categorized data
  const categories = {}

  // Iterate through each object in the input array
  mergedJourneysSet.forEach(item => {
    const subtitle = item.subtitle.subtitle // Extract the subtitle

    // If the category does not exist, initialize it
    if (!categories[subtitle]) {
      categories[subtitle] = []
    }

    // Add the current item to its corresponding category
    categories[subtitle].push(item)
  })

  // Flatten the categorized data into a single array and sort
  const flattenedData = Object.entries(categories).flatMap(
    ([subtitle, items]) => {
      // Return an array with an added weekNumber property for sorting
      return items.map(item => ({
        ...item,
        weekNumber:
          subtitle === "Before kick-off"
            ? 0
            : parseInt(subtitle.replace("Week ", ""), 20) || Infinity,
      }))
    }
  )

  // Sort the array by weekNumber, maintaining the order within the same week
  flattenedData.sort((a, b) => a.weekNumber - b.weekNumber)

  // sorr the data again for slugOrder so that within week we also get the data sorted.
  const sortedAgain = sortDataBySlugOrder(flattenedData)

  // Remove the temporary weekNumber property before returning the data
  return sortedAgain.map(({ weekNumber, ...item }) => item)
}

export const handleFeedsSelectJourneyChange = (
  e,
  journeysList,
  setSelectedJourney,
  setSelectedActivity
) => {
  const matchedJourney = journeysList.find(
    journey => journey.contentful_id === e.target.value
  )

  if (matchedJourney) {
    setSelectedJourney(matchedJourney)
    if (matchedJourney.activities && matchedJourney.activities.length) {
      setSelectedActivity(matchedJourney.activities[0])
    }
  } else {
    console.log("No matching journey found")
  }
}

export const handleFeedSelectActivityChange = (
  e,
  selectedJourney,
  setSelectedActivity
) => {
  const matchedActivity = selectedJourney.activities
    ? selectedJourney.activities.find(
        activity => activity.contentful_id === e.target.value
      )
    : undefined
  if (matchedActivity) {
    setSelectedActivity(matchedActivity)
  } else {
    console.log("No matching journey found")
  }
}

export const resolveTeamName = (item, langCode, i18n) => {
  if (item.type === "country") {
    if (item.teamName === "TE") {
      return "TE Global"
    }
    const code = item.teamName === "UK" ? "GB" : item.teamName
    return i18n.getName(code, langCode)
  }
  return item.teamName
}

// Used on scoreboard page.
export const updateListItemOpenState = (produce, data, itemId, isOpen) => {
  return produce(data, draft => {
    const itemIndex = draft.findIndex(c => c.id === itemId)
    if (itemIndex !== -1) {
      draft[itemIndex].isOpen = isOpen
    }
  })
}

// used on signup page
export const transformSegmentsAndBUs = data => {
  if (!Array.isArray(data)) {
    console.error("Expected an array for data")
    return [] // or return a suitable default value
  }

  const segmentMapping = {}

  data.forEach(item => {
    // Ensure each item is an object and has the required properties
    if (
      typeof item === "object" &&
      item !== null &&
      "id" in item &&
      "companyId" in item &&
      "name" in item &&
      "segment" in item
    ) {
      const { id, companyId, name, segment } = item

      // Initialize segment if not already present
      if (!segmentMapping[segment]) {
        segmentMapping[segment] = {
          segment,
          businessUnits: [],
        }
      }

      // Add the business unit to the segment
      segmentMapping[segment].businessUnits.push({ id, companyId, name })
    } else {
      console.warn("Invalid item encountered and will be skipped:", item)
    }
  })

  // Convert the segment mapping into an array of segments
  return Object.values(segmentMapping)
}

// for setting language to default language on user login
// Helper to check if the language has already been set
export const hasLanguageBeenSet = () => !!localStorage.getItem("languageSet")

// Helper to mark the language as set
export const markLanguageAsSet = () =>
  localStorage.setItem("languageSet", "true")

// Helper to remove the language set flag (e.g., on logout)
export const clearLanguageSetting = () => localStorage.removeItem("languageSet")

export const separateAndSummarizeRiddleData = data => {
  let referralPoints = 0
  let referralCount = 0
  let referralId = ""
  const riddleObjects = []

  data.forEach(item => {
    if (item.type === "REFERRAL") {
      referralPoints += item.points
      referralCount += 1
      if (!referralId) {
        referralId = item.id
      }
    } else if (item.type === "RIDDLE") {
      riddleObjects.push(item)
    }
  })

  const referralObject = {
    points: referralPoints,
    id: referralId,
    comment: "",
    referralCount,
    type: "REFERRAL",
    backgroundColor: "#ABC8EA",
    localization: null,
  }

  if (referralPoints > 0) {
    return [...riddleObjects, referralObject]
  } else {
    return [...riddleObjects]
  }
}
