const _ = require("lodash");

function objectToCamelCase(arg) {
  return _.transform(arg, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[key[0] !== _ ? camelKey : key] = _.isObject(value)
      ? objectToCamelCase(value)
      : value;
  });
}
function objectToSnakeCase(arg) {
  return _.transform(arg, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.snakeCase(key);
    acc[key[0] !== _ ? camelKey : key] = _.isObject(value)
      ? objectToSnakeCase(value)
      : value;
  });
}
function payloadToSnakeCase(payload) {
  if (_.isPlainObject(payload)) {
    return objectToSnakeCase(payload);
  }
  if (_.isArray(payload)) {
    return (payload || []).map(objectToSnakeCase);
  }
  return payload;
}
function rasterizeError(error) {
  const e = {
    property: null,
    message: error.message,
    location: error.location,
  };
  if (error.keyword === "required") {
    e.property = error.params.missingProperty;
  } else if (error.keyword === "format" || error.keyword === "pattern") {
    e.property = error.dataPath.substr(1);
  } else {
    e.property = error.path;
  }
  return e;
}

function rasterizeErrors(error) {
  const errors = {
    ...error,
    errors: [],
  };
  try {
    errors.errors = (error.errors || []).map(rasterizeError);
  } catch (e) {
    errors.errors = [];
  }
  delete errors.responseErrors;
  return errors;
}

export { objectToCamelCase, payloadToSnakeCase, rasterizeErrors };
