// import ApolloClient from "apollo-boost"
import { ApolloClient } from "apollo-client"
// import { createHttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"
import { createUploadLink } from "apollo-upload-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { withClientState } from "apollo-link-state"
import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import { navigate } from "gatsby"
import { isAuthRoute, isBrowser } from "../utilities/helpers"
import fetch from "isomorphic-fetch"
import resolvers, { defaults } from "./../resolvers"
import api from "../utilities/api"

export const getToken = () => {
  const sessionToken = sessionStorage.getItem("auth_token")
  const localToken = localStorage.getItem("auth_token")
  if (isBrowser() && localToken) {
    api.setAuthorizationXToken(localToken)
    return localToken
  } else if (isBrowser() && sessionToken) {
    api.setAuthorizationXToken(sessionToken)
    return sessionToken
  }
  return ""
}

// const httpLink = createHttpLink({
//   uri: "https://1change-api.fieldtest.xyz/graphql",
// })

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes("Not authenticated as user.")) {
        if (!isAuthRoute(window.location.href)) {
          // User is not authenticated, so redirect them to the login page (unless they are on the welcome page)
          navigate("/login")
        }
      }
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const uploadLink = createUploadLink({
  // uri: "http://localhost:8000/graphql",
  uri: "https://prod.cloud.1change.co/graphql",
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-token": token ? `${token}` : "",
    },
  }
})

const cache = new InMemoryCache()

const stateLink = withClientState({
  cache,
  resolvers,
  defaults,
})

const link = ApolloLink.from([stateLink, authLink, errorLink, uploadLink])

export const client = new ApolloClient({
  link,
  cache,
  fetch,
})
